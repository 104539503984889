import logo from './Goondocks.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>Discovering Insights Through Machine Learning</h2>
        <h3>What We Do:</h3>
        <p>At Goondocks Inc, we are at the forefront of developing cutting-edge artificial intelligence solutions aimed at enhancing social engagement. Our mission is to create innovative AI technologies that foster meaningful connections, facilitate dynamic interactions, and enrich the social experiences of individuals and communities. By leveraging advanced machine learning algorithms and data analytics, we strive to build platforms that understand and respond to human needs, driving deeper engagement and transforming the way people connect in the digital age.</p>
      </header>
    </div>
  );
}

export default App;
